import { useAttractionsContext } from '@/common/app/contexts';
import { IFullList } from '@/common/service/api/Activity/Activity.domain';
import { ICategoriesItem } from '@/common/service/api/Categories/Categories.domain';
import { TFaqItem } from '@/common/service/api/FAQ/FAQ.domain';
import EmptyAttractionsPage from '@/entities/Attractions/ui/EmptyAttractionsPage/EmptyAttractionsPage';
//import SoldOutPage from '@/entities/Attractions/ui/SoldOutPage/SoldOutPage';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import FAQ from '@/shared/FAQ/ui/FAQ';
import TopAttractions from '@/shared/TopAttractions/ui/TopAttractions';
import { ActivitiesList } from './ActivitiesList';
import { TLandmarksShort } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import { TopLandmarks } from '@/shared/TopLandmarks/ui/TopLandmarks';
import { AttractionsDescription } from './AttractionsDescription/AttractionsDescription';
import { IAttributes } from '@/common/app/constants/attributions';
import { NewFeaturedActivities } from '@/shared/FeaturedActivities/ui/NewFeaturedActivities';

type Props = {
    isEmpty: boolean;
    featuredCategories?: ICategoriesItem[];
    currentDestination: IDestinationList;
    featuredActivitiesTitle: string;
    fullList: IFullList;
    faq: TFaqItem[];
    title: string;
    listNameGA3?: string;
    isCategoryPage?: boolean;
    currentCategory?: IDestinationCategory;
    landmarks?: TLandmarksShort;
    categoryDescription?: string;
    availableAttributes: IAttributes[];
    noRecommends: boolean;
    placeholders: {
        [key: string]: string;
    };
};

export const AttractionsList = ({
    faq,
    isEmpty,
    currentDestination,
    currentCategory,
    title,
    fullList,
    featuredActivitiesTitle,
    featuredCategories,
    listNameGA3,
    isCategoryPage,
    landmarks,
    categoryDescription,
    availableAttributes,
    noRecommends,
    placeholders,
}: Props) => {
    const { state } = useAttractionsContext();

    const { recommends } = state;

    const item_list_id = currentCategory
        ? `${currentDestination.id}/${currentCategory.id}`
        : currentDestination.id;

    const item_list_name = currentCategory
        ? `${currentDestination.name}/${currentCategory.name}`
        : currentDestination.name;

    if (isEmpty) {
        return (
            <EmptyAttractionsPage
                redirectToDestination={`/attractions/${currentDestination.slug}`}
                isCategoryPage={isCategoryPage}
                destinationName={currentDestination.name}
            />
        );
    }

    // if (isSoldOut) {
    //     return (
    //         <SoldOutPage
    //             currentDestination={currentDestination}
    //             fullList={fullList}
    //             recommends={soldOutListRecommends ?? []}
    //             isCategoryPage={isCategoryPage}
    //             title={title}
    //             item_list_id={item_list_id}
    //             item_list_name={item_list_name}
    //         />
    //     );
    // }

    return (
        <>
            <AttractionsDescription
                title={title}
                description={
                    isCategoryPage ? categoryDescription : fullList.destination_description
                }
            />

            {Boolean(featuredCategories?.length) && (
                <TopAttractions
                    featuredCategories={featuredCategories ?? []}
                    destinationName={currentDestination.name}
                />
            )}
            {!noRecommends && (
                <NewFeaturedActivities
                    activities={recommends ?? []}
                    title={featuredActivitiesTitle}
                    isFromSearch
                    item_list_id={item_list_id}
                    item_list_name={item_list_name}
                    placeholders={placeholders}
                />
            )}
            <ActivitiesList
                title={title}
                fullList={fullList}
                listNameGA3={listNameGA3}
                listName={`${currentDestination.name}${
                    currentCategory ? '/' + currentCategory.name : ''
                }`}
                listId={`${currentDestination.id}${
                    currentCategory ? '/' + currentCategory.id : ''
                }`}
                availableAttributes={availableAttributes}
            />
            <FAQ data={faq}>
                <TopLandmarks
                    landmarks={landmarks}
                    destinationName={currentDestination.name}
                    destinationSlug={currentDestination.slug}
                    stateCode={currentDestination.state.code}
                />
            </FAQ>
        </>
    );
};
